:root {
  /* Colors */
  --text-color-grey: #696969;
  --background-color-pink: #ffd1dc;
  --color-black: #000;
  --color-white: #fffafa;

  /* Fonts */
  --robo: "Roboto Mono", monospace;
  --robo-slab: "Roboto Slab", serif;

  /* Mobile Screen Size */
  --mobile-breakpoint: 744px;
}
