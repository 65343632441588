@import "/src/data/constants.css";

.textContainer {
  font-size: 1.4rem;
  /* color: var(--text-color-grey); */
  position: absolute;
  bottom: 5%;
  right: 2%;
  width: 70%;

  @media screen and (max-width: 744px) {
    width: 75%;
    font-size: 0.97rem;
    bottom: 3%;
    right: 3%;
  }
}

.tabbedText {
  margin-left: 8.5%;
  font-family: var(--robo);
}

.bulletText {
  font-family: var(--robo);
  font-weight: 700;
}

.normalText {
  font-family: var(--robo);
  font-weight: 200;
}

.bullet {
  margin-right: 5px;
  color: var(--color-black);
}

.boldText {
  font-family: var(--robo);
  font-weight: 600;
}

.textLine {
  display: flex;
  align-items: center;
}
