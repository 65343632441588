@import "/src/data/constants.css";

.navContainer {
  margin-top: 1%;

  display: flex;
  flex-direction: column;
  @media screen and (max-width: 774px) {
    margin-top: 3%;
  }
}

.navText {
  font-family: var(--robo);
  font-size: 1.4rem;
  font-weight: 700;
  color: var(--color-black);

  @media screen and (max-width: 774px) {
    font-size: 1.2rem;
  }
}

.activeNavText {
  font-family: var(--robo);
  font-size: 1.4rem;
  font-weight: 700;
  color: var(--color-white);

  @media screen and (max-width: 774px) {
    font-size: 1.2rem;
  }
}

.navText:hover {
  color: var(--color-white);
  transition: 0.3s ease-out;
}
