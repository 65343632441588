.scrollDown {
  position: absolute;
  bottom: 20px;
  left: 20%;
  transform: translateX(-50%);
  cursor: pointer;
  animation: bounce 2s infinite;

  @media screen and (max-width: 774px) {
    left: 10%;
  }
}

.arrow {
  width: 20px;
  height: 20px;
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}
