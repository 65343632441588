@import "/src/data/constants.css";

.socialContainer {
  margin-top: 0.5%;

  @media screen and (max-width: 774px) {
    margin-top: 2%;
  }
}

.icon {
  margin-right: 2%;
  font-size: 28px;
  @media screen and (max-width: 774px) {
    font-size: 20px;
    margin-right: 5%;
  }
}

a {
  color: var(--color-black);
  text-decoration: none;
}

a:hover {
  color: var(--color-white);
  transition: 0.3s ease-out;
}
