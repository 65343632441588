@import "/src/data/constants.css";

.projectContainer {
  position: absolute;
  right: 0;
  margin-right: 5%;
  margin-top: 10px;
  width: 55%;

  @media screen and (max-width: 744px) {
    width: 68%;
  }
}

.container {
  margin-bottom: 20px;

  @media screen and (max-width: 744px) {
    margin-bottom: 15px;
  }
}

.dotWithLine {
  display: flex;
  align-items: center;
}
.dot {
  width: 10px; /* Size of the dot */
  height: 10px;
  border-radius: 50%;
  background-color: black;
  margin-right: 10px; /* Spacing between dot and line */
}
.lineAfter {
  flex: 3; /* Flexible width to fill remaining space */
  height: 1px; /* Height of the line */
  background-color: black;
}

.line {
  /* flex: 1; Flexible width to fill remaining space */
  width: 70px;
  height: 1px; /* Height of the line */
  background-color: black;

  @media screen and (max-width: 744px) {
    width: 20px;
  }
}

.projectName {
  margin: 0px 5px 0px 5px;
  font-size: 2rem;
  font-family: --robo;

  @media screen and (max-width: 744px) {
    font-size: 1rem;
  }
}

.techStackText {
  font-family: --robo;
  font-size: 1.5rem;
  font-style: italic;

  @media screen and (max-width: 744px) {
    font-size: 0.65rem;
  }
}

.bodyText {
  font-family: --robo;
  font-size: 1rem;

  @media screen and (max-width: 744px) {
    font-size: 0.6rem;
  }
}
