@import "/src/data/constants.css";

.nameContainer {
  display: flex;
  margin-top: 2%;
  color: #fffafa;
  align-items: center;

  @media screen and (max-width: 774px) {
    margin-top: 5%;
  }
}

.titleContainer {
  display: flex;
}

.title {
  color: #696969;
  font-family: var(--robo);
  font-weight: 600;
  @media screen and (max-width: 774px) {
    font-size: 14px;
  }
}

.firstName {
  margin-right: 15px;
  /* text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000; */
  font-size: 64px;
  font-family: var(--robo-slab);
  font-weight: 700;
  -webkit-text-stroke-width: 1.3px;
  -webkit-text-stroke-color: var(--color-black);

  @media screen and (max-width: 774px) {
    font-size: 25px;
    font-weight: 900;
    margin-right: 10px;
  }
}

.dot {
  color: var(--color-black);
}

.lastName {
  margin-left: 15px;
  /* text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000; */
  font-size: 64px;
  font-family: var(--robo-slab);
  font-weight: 700;
  -webkit-text-stroke-width: 1.5px;
  -webkit-text-stroke-color: var(--color-black);

  @media screen and (max-width: 774px) {
    font-size: 25px;
    font-weight: 900;
    margin-left: 10px;
  }
}
