@import "/src/data/constants.css";

.container {
  height: 100vh;
  width: 100%;
  position: absolute;
  background-color: var(--background-color-pink);
}

.topBar {
  margin-left: 5%;
}

.centerContainer {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 80%;
  width: 90%;
  border: 3px solid var(--color-black);
  border-radius: 15px;
  margin: 5%;
  position: relative;

  @media screen and (max-width: 744px) {
    height: 93%;
    width: 86%;
    margin: 7%;
  }
}
