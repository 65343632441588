.projectContainer {
  position: absolute;
  right: 0;
  margin-right: 5%;
  margin-top: 10px;
  width: 55%;
  overflow: scroll;

  @media screen and (max-width: 742px) {
    width: 68%;
  }
}

.bodyContainer {
  height: 99%;
  position: relative;
  overflow: scroll;
}
